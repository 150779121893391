import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NewLineToBrPipe } from './new-line-to-br.pipe';
import { TruncatePipe } from './truncate.pipe';
import { ElapsedTimePipe } from './elapsed-time.pipe';
import { FlCurrencyPipe } from './fl-currency.pipe';
import { DateDiffPipe } from './date-diff.pipe';
import { ChatDateDisplayPipe } from './chat-date-display.pipe';
import { MarkdownToHtmlPipe } from './markdown-to-html.pipe';
import { Sanitize } from './sanitize.pipe';
import { DisplayUrl } from './display-url.pipe';

@NgModule({
  declarations: [
    ChatDateDisplayPipe,
    NewLineToBrPipe,
    TruncatePipe,
    ElapsedTimePipe,
    FlCurrencyPipe,
    DateDiffPipe,
    MarkdownToHtmlPipe,
    Sanitize,
    DisplayUrl,
  ],
  imports: [CommonModule],
  providers: [DatePipe],
  exports: [
    ChatDateDisplayPipe,
    NewLineToBrPipe,
    TruncatePipe,
    ElapsedTimePipe,
    FlCurrencyPipe,
    DateDiffPipe,
    MarkdownToHtmlPipe,
    Sanitize,
    DisplayUrl,
  ],
})
export class PipesModule {}
